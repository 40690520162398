import classNames from 'classnames';
import React, {ComponentProps, ReactElement} from 'react';

import {getResourcesURL} from '../../../urls';
import Col from '../../common/Col';
import Row from '../../common/Row';
import Button from './buttons/Button';
import ResourceCard from './ResourceCard';

type Props = {
  className?: string;
  title: string;
  resources: ComponentProps<typeof ResourceCard>['data'][];
  bigLabel?: boolean;
};

const RelatedResources = ({className, title, resources, bigLabel}: Props): ReactElement => {
  return (
    <Col className={className}>
      <Row className="w-full items-center justify-between mb-lg gap-sm">
        <h1 className={classNames('text-primary-800 font-semibold leading-tight', bigLabel ? 'text-huge' : 'text-3xl')}>
          {title}
        </h1>
        <Button
          className="block mobile:hidden w-fit h-fit"
          href={getResourcesURL()}
          variant="secondary"
          accessory="forward"
        >
          See all
        </Button>
      </Row>
      <Row className="flex-nowrap mobile:overflow-x-scroll gap-sm mobile:-mx-md mobile:px-md">
        {resources.map((rc, i) => (
          <ResourceCard
            key={`resources_card_${i}`}
            className={classNames(
              'flex-1 mobile:flex-none mobile:flex-shrink-0',
              resources.length === 1 && 'max-w-[50%] mobile:max-w-none',
              resources.length > 1 ? 'mobile:w-10/12' : 'mobile:w-full'
            )}
            data={rc}
          />
        ))}
      </Row>
    </Col>
  );
};

export default RelatedResources;
